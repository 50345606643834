/** @typedef {import('../../../@types/api-response').APIResponse} APIResponse */
import requestExodusConfig from 'config/requestExodus';
import exodusInstance from './instances/exodus';

const requestExodus = requestExodusConfig();
const requestExodusNoError = requestExodusConfig(false);
const authRequestExodus = (headersFromNextjsAPI) =>
  requestExodusConfig(false, undefined, true, {
    'x-real-user-agent': headersFromNextjsAPI?.['x-real-user-agent'] || '-',
    'x-real-user-ip': headersFromNextjsAPI?.['x-real-user-ip'] || '-',
    'x-forwarded-for': headersFromNextjsAPI?.['x-forwarded-for'] || '-',
  });

/**
 * Email Login
 * @param {string} user - email / username
 * @param {string} password - password
 */
const emailLogin = ({ user, password }, headersFromNextjsAPI) =>
  authRequestExodus(headersFromNextjsAPI).post('/login', { user, password });

/**
 * Facebook Login
 * @param {string} id - facebook user id
 * @param {string} idToken - facebook access token
 */
const fbLogin = ({ id, idToken }, headersFromNextjsAPI) =>
  authRequestExodus(headersFromNextjsAPI).post('/login/facebook', {
    fb_access_token: idToken,
    fb_user_id: id,
  });

/**
 * Google Login
 * @param {string} id - google account id
 * @param {string} idToken - google access token
 */
const googleLogin = ({ id, idToken }, headersFromNextjsAPI) =>
  authRequestExodus(headersFromNextjsAPI).post('/login/google', {
    google_user_id: id,
    google_id_token: idToken,
  });

const logout = ({ token }, headersFromNextjsAPI) =>
  authRequestExodus(headersFromNextjsAPI).post('/logout', null, {
    headers: {
      Authorization: token,
    },
  });

function validateRegisterForm(field, value, key) {
  const availableFields = ['fullname', 'username', 'password'];

  if (availableFields.indexOf(field) < 0) {
    return Promise.reject(new Error('field is not available'));
  }

  return requestExodusNoError.post(`/registration/check/${field}`, {
    key,
    [field]: value,
  });
}

/**
 * Forgot password request by email
 * @param {string} email - user email
 */
const forgotpassword = (email) =>
  requestExodus.post('/user/setting/password/forgot', { email });

/**
 * Reset password
 * @param {string} userid - user id from link
 * @param {string} passkey - unique passkeyfrom link
 * @param {string} newpass - new password from form
 * @param {string} confirmpass - password confirmation from form
 *
 */
const resetPassword = (userid, passkey, newpass, confirmpass) =>
  requestExodus.post('/user/setting/password/reset', {
    user_id: userid,
    token: passkey,
    password: newpass,
    confirm_password: confirmpass,
  });

/**
 * Validate Social Media
 * @param {string} socialId
 * @param {string} socialToken
 * @param {string} email
 * @param {string} type
 * @returns {Promise<APIResponse>}
 */
const validateSocialMedia = (socialId, socialToken, email, type) =>
  requestExodusNoError.post('/registration/check/sns-email', {
    social_id: socialId,
    social_token: socialToken,
    email,
    type,
  });

const verifyRegistration = ({
  email,
  username,
  fullname,
  password,
  verificationToken,
}) =>
  requestExodusNoError.post('/registration/check/web', {
    email,
    username,
    fullname,
    password,
    verification_token: verificationToken,
  });

/**
 * Verify Email Registration
 * @param {string} key
 * @param {string} email
 * @param {string} type
 * @returns {Promise<APIResponse>}
 */
const verifyEmailRegistration = (key, email, type) =>
  requestExodusNoError.post('/registration/v3/check/email', {
    key,
    email,
    type,
  });

/**
 * Valdidate Email OTP
 * @param {string} key
 * @param {string} otp
 * @returns {Promise<APIResponse>}
 */
const validateEmailOtp = (key, otp) =>
  requestExodus.post('/registration/v3/otp/email', {
    key,
    otp,
  });

/**
 * Verify Phone Registration
 * @param {string} key
 * @param {string} code
 * @param {string} phone
 * @param {string} channel
 * @returns {Promise<APIResponse>}
 */
const verifyPhoneRegistration = (key, code, phone, channel) =>
  exodusInstance.noAlert.post('/registration/v3/check/phone', {
    key,
    code,
    phone,
    channel,
  });

/**
 * Validate Phone OTP
 * @param {string} key
 * @param {string} otp
 * @returns {Promise<APIResponse>}
 */
const validatePhoneOtp = (key, otp, headersFromNextjsAPI) =>
  authRequestExodus(headersFromNextjsAPI).post('/registration/v3/otp/phone', {
    key,
    otp,
  });

/**
 * Generate Registration Key
 * @returns {Promise<APIResponse>}
 */
const generateRegistrationKey = () =>
  requestExodus.post('/registration/check/gen-key');

export default {
  emailLogin,
  fbLogin,
  googleLogin,
  logout,
  validateRegisterForm,
  forgotpassword,
  resetPassword,

  // NEW REGISTARTION FLOW
  verifyRegistration,
  validateSocialMedia,
  verifyEmailRegistration,
  validateEmailOtp,
  verifyPhoneRegistration,
  validatePhoneOtp,
  generateRegistrationKey,
};
