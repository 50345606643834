const formTypes = {
  SELECTION: 'SELECTION_FORM',
  EMAIL: 'EMAIL_FORM',
  PHONE: 'PHONE_FORM',
  SEND_OTP_METHOD: 'SEND_OTP_METHOD',
  CHECK_PHONE: 'CHECK_PHONE_FORM',
  NOTIFY_VERIFICATION_PHONE: 'NOTIFY_VERIFICATION_PHONE',
  NOTIFY_VERIFICATION_EMAIL: 'NOTIFY_VERIFICATION_EMAIL',
  VERIFY_EMAIL: 'VERIFY_EMAIL_FORM',
  VERIFY_PHONE: 'VERIFY_PHONE_FORM',
  PROFILE_PHOTO: 'PROFILE_PHOTO_FORM',
  CONNECT_FB: 'CONNECT_FB_FORM',
  DISCOVER_PEOPLE: 'DISCOVER_PEOPLE_FORM',
  DISCOVER_STOCK: 'DISCOVER_STOCK_FORM',
  LOADING: 'LOADING_FORM',
  SUCCESS: 'SUCCESSFUL_REGISTRATION',
  PHONE_ERROR: 'PHONE_ERROR_FORM',
  OTP_LIMIT: 'OTP_LIMIT',
};

const registerTypes = {
  EMAIL: 'email',
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
};

const newRegisterTypes = {
  EMAIL: 'IDENTITY_TYPE_EMAIL',
  GOOGLE: 'IDENTITY_TYPE_GOOGLE',
  FACEBOOK: 'IDENTITY_TYPE_FACEBOOK',
};

const emailVerifyStatus = {
  SIGNIN: 'STATE_CHECK_EMAIL_SIGNIN',
  REGISTER: 'STATE_CHECK_EMAIL_SIGNIN',
};

const phoneVerifyStatus = {
  FAIL: 'VERIFY_STATUS_FAIL',
  SUCCESS: 'VERIFY_STATUS_SUCCESS',
};

const mappingNewRegisterTypes = {
  [registerTypes.EMAIL]: newRegisterTypes.EMAIL,
  [registerTypes.GOOGLE]: newRegisterTypes.GOOGLE,
  [registerTypes.FACEBOOK]: newRegisterTypes.FACEBOOK,
};

const requestSchema = {
  data: null,
  isLoading: false,
  message: '',
  error: '',
};

const validationReqSchema = {
  ...requestSchema,
  isChecked: false,
};

const hints = {
  NAME: {
    title: "What's Your Name",
    description:
      'Using your real name makes it easier for you to be recognised and build a network.',
  },
  EMAIL: {
    title: "What's Your Email",
    description:
      'You will need your email to login and reset your password in the future.',
  },
  USERNAME: {
    title: 'Pick a Username',
    description:
      'Your username is your brand. Pick one that is professional and credible. You cannot change it later.',
  },
  PASSWORD: {
    title: 'Create a Password',
    description:
      'Your password needs to be at least 6 or more characters long. Pick a password that you can remember.',
  },
};

const errorMessage = {
  EMAIL_REGISTERED:
    'Email ini sudah pernah terdaftar di Stockbit. Silahkan coba login.',
  PHONE_REGISTERED:
    'Nomor telepon ini sudah pernah terdaftar di Stockbit. Silahkan coba login.',
  FAIL_TO_VERIFY_PHONE: 'Gagal verifikasi nomor telepon. Silahkan coba lagi.',
  FAIL_TO_VERIFY_EMAIL: 'Gagal verifikasi email. Silahkan coba lagi.',
  SOCIAL_ACCOUNT_REGISTERED:
    'Akun social sudah terdaftar, silakan gunakan akun social lain',
  SOCIAL_EMAIL_REGISTERED: 'Email Sudah Terdaftar',
};

const emailStatuses = {
  VERIFIED: 'verified',
  NOT_VERIFIED: 'not_verified',
};

const LS_EMAIL_REGISTER = 'emailRegister';
const LS_PHONE_REGISTER = 'phoneRegister';

export {
  emailStatuses,
  emailVerifyStatus,
  errorMessage,
  formTypes,
  hints,
  mappingNewRegisterTypes,
  newRegisterTypes,
  phoneVerifyStatus,
  registerTypes,
  requestSchema,
  validationReqSchema,
  LS_EMAIL_REGISTER,
  LS_PHONE_REGISTER,
};
