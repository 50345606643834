import moment from 'moment';
import Router from 'next/router';

import { isClient } from 'constants/app';
import ERROR_MESSAGE from 'constants/errorMessage';
import { REGISTRATION_DATA } from 'constants/localstorage';
import handleErrorMessageAPI from 'global/AlertErrorMessage';

import { LS_EMAIL_REGISTER, errorMessage as ErrorMessage } from './constants';
import { RegisterState } from './types';

export const storeData = (data: RegisterState): void => {
  if (data && isClient()) {
    const newData = { ...data, storedAt: moment() };
    localStorage.setItem(REGISTRATION_DATA, JSON.stringify(newData));
  }
};

export const removeData = (): void => {
  if (isClient()) {
    const data = localStorage.getItem(REGISTRATION_DATA);
    if (data) {
      localStorage.removeItem(REGISTRATION_DATA);
    }
  }
};

export const onUpdateAvatarIds = (userId: string): void => {
  const avatarUserIds: string[] =
    localStorage.getItem('avatarUserIds')?.split(',') || [];
  if (!avatarUserIds.includes(userId)) {
    avatarUserIds.push(userId);
  }
  localStorage.setItem('avatarUserIds', avatarUserIds.join(','));
};

interface errorMessage {
  response: {
    data: {
      message: string;
    };
  };
  message?: string;
}

export const formatErrorMessage = (error: string | errorMessage): string => {
  let message: string;
  if (typeof error === 'string') {
    message = error;
  } else {
    message = error?.response?.data?.message || error?.message || '';
  }
  message = message.replace(/;/g, ''); // Replace all occurrences of ';' with ''
  message = message.replace(' |error_type=1', '');
  return message;
};

interface EmailValidationResult {
  error?: string;
  message?: string;
  field: string;
}

export const validateEmail = (value: string): EmailValidationResult => {
  // eslint-disable-next-line no-useless-escape
  if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/)) {
    return {
      error: 'Email format is not valid!',
      field: 'email',
    };
  }
  return {
    message: 'Email format is valid',
    field: 'email',
  };
};

export const validatePasswordConf = (
  password: string,
  passwordConf: string,
): string | null => {
  if (password !== passwordConf) {
    return 'Password dan Confirm Password tidak cocok';
  }
  return null;
};

export const handleRegistrationError = (
  responseMessage: string | undefined,
  email: string,
): void => {
  if (
    responseMessage === ErrorMessage.SOCIAL_ACCOUNT_REGISTERED ||
    responseMessage === ErrorMessage.SOCIAL_EMAIL_REGISTERED
  ) {
    localStorage.setItem(LS_EMAIL_REGISTER, email);
    Router.push('/login');
    handleErrorMessageAPI(
      ErrorMessage.EMAIL_REGISTERED,
      ERROR_MESSAGE.ALERT_RED,
    );
    return;
  }

  handleErrorMessageAPI(responseMessage, ERROR_MESSAGE.ALERT_RED);
};
