import numberUtil from './number';

const MAX_USERNAME_LENGTH = 17;

function normalUsername(name) {
  const draft = name.replace(/\s/g, '').toLowerCase();

  if (draft.length > MAX_USERNAME_LENGTH) {
    return draft.substr(0, MAX_USERNAME_LENGTH);
  }
  return draft;
}

function randomUsername(name) {
  const draft = normalUsername(name);
  const randomNumber = numberUtil.getRandomInt(1, 999);

  if (draft.length === MAX_USERNAME_LENGTH) {
    return `${draft.substr(0, -3)}${randomNumber}`;
  }
  return `${draft}${numberUtil.getRandomInt(1, 999)}`;
}

export default {
  normalUsername,
  randomUsername,
};
